@import "~assets/styles/_variables.scss";

.ReactTable.AnomalyDrawerTable {
    .rt-thead .rt-th.-sort-asc,
    .rt-thead .rt-th.-sort-desc {
        box-shadow: none;

        svg {
            opacity: 1;
            fill: $purple;
            transition: transform 0.2s ease;
        }
    }

    .rt-thead .rt-th.-sort-asc {
        svg {
            transform: rotate(180deg);
        }
    }
}

$scrollSpacerHeight: 1.5rem;

.TableScrollWrapper {
    overflow-x: scroll;
    overflow-y: hidden;
    position: relative;

    &::-webkit-scrollbar {
        height: $scrollSpacerHeight;
        background-color: transparent;
    }

    &::-webkit-scrollbar-thumb {
        background-color: rgba($grey-graphite, 0.5);
        border-width: 0.5rem;
        border: 0.5rem $white solid;
        border-radius: 1rem;
    }

    .MorphioTable {
        table-layout: fixed;
    }

    &.emptyWrapper {
        &::-webkit-scrollbar-thumb {
            background-color: transparent;
        }
    }
}

.ScrollSpacer {
    display: block;
    z-index: 1;
    height: 1px;
    width: 100%;
    background-color: $grey-lines;
    transform: translateY(-$scrollSpacerHeight);

    &.isPaginated {
        background-color: none;
    }
}

.MorphioTable {
    font-family: $primary-font;
    max-width: 100%;
    border-collapse: unset !important;
    border-top: 1px solid #eaf0f7;

    .MuiTableCell-root {
        font-family: $primary-font;
        font-size: 0.875rem;
        overflow-wrap: break-word;
        color: $grey-graphite;
        border-color: #eaf0f7;
        letter-spacing: 0;
        background-color: $white;
        transition: background-color 0.3s ease-in-out, border-color 0.3s ease-in-out;
    }
    .MuiTableHead-root {
        border-top: 1px solid #eaf0f7;

        .MuiTableCell-head {
            background-color: #fbfcfc;
            color: $grey-graphite;
            font-weight: 700;
            line-height: 1rem;

            &:not(.noDividers) {
                border-left: 1px solid #eaf0f7;
            }

            &:first-of-type,
            &.emptyCell.noDividers {
                border-left: none;
            }

            &.freezeCell {
                position: sticky;
                left: 0;
                z-index: 2;
                transition: box-shadow 0.3s ease-in-out;

                &::after {
                    content: "";
                    position: absolute;
                    top: 0;
                    width: 0.25rem;
                    height: 100%;
                    left: calc(100% - 0.25rem);
                    z-index: -1;
                    box-shadow: 0.25rem 0 0.25rem 0 rgba(51, 57, 68, 0.07);
                    opacity: 0;
                    transition: opacity 0.3s ease-in-out;
                }

                &.isScrolled {
                    &::after {
                        opacity: 1;
                    }
                }
            }

            .optionsIcon {
                transform: rotate(90deg);
                order: -2;
                opacity: 0;
                cursor: pointer;
            }

            &:hover {
                .optionsIcon {
                    opacity: 1;
                }
            }

            .MuiTableSortLabel-root {
                padding: 1rem 0.625rem 1rem 0.875rem;
                display: flex;
                align-items: center;
                justify-content: space-between;
                transition: color 0.2s ease;
                transform: translateY(1px);
                min-height: 3.5rem;

                // Removing default sort arrow
                .MuiSvgIcon-root {
                    display: none;
                }

                .arrowSort {
                    min-width: 0.75rem;
                    opacity: 0;
                    transition: opacity 0.2s ease, transform 0.2s ease, fill 0.2s ease;
                }

                &:hover {
                    color: $purple;

                    .arrowSort {
                        opacity: 1;
                    }
                }

                &.MuiTableSortLabel-active {
                    color: $purple;

                    .arrowSort {
                        opacity: 1;
                        fill: $purple;
                    }
                }
            }

            &[aria-sort="descending"] {
                .MuiTableSortLabel-active {
                    .arrowSort {
                        transform: rotate(180deg);
                    }
                }
            }
        }

        .bulkActionButton {
            display: none;
        }
    }
    .MuiTableBody-root {
        .MuiTableRow-root.Mui-selected {
            background-color: $purple-light;

            .MuiTableCell-root {
                background-color: $purple-light !important;
            }
        }

        .MuiTableCell-body {
            padding: 0.75rem 0.875rem;

            &.expandContentHeight {
                height: 0;
            }

            &.freezeCell {
                position: sticky;
                left: 0;
                z-index: 2 !important;

                &::after {
                    content: "";
                    position: absolute;
                    top: 0;
                    width: 0.25rem;
                    height: 100%;
                    left: calc(100% - 0.25rem);
                    z-index: -1;
                    box-shadow: 0.25rem 0 0.25rem 0 rgba(51, 57, 68, 0.07);
                    opacity: 0;
                    transition: opacity 0.3s ease-in-out;
                }

                &.isScrolled {
                    &::after {
                        opacity: 1;
                    }
                }
            }

            .collapseToggle {
                display: flex;
                align-items: flex-start;
                cursor: pointer;

                > svg {
                    transform: rotate(-90deg) translateX(-0.25rem);
                    transform-origin: center;
                    min-width: 0.625rem;
                    min-height: 0.625rem;
                    transition: fill 0.3s ease-in-out, transform 0.3s ease-in-out;
                }

                > span {
                    margin-left: 0.625rem;
                    display: block;
                    transition: color 0.3s ease-in-out;
                }

                &:hover,
                &.open {
                    > svg {
                        fill: $purple;
                    }

                    > span {
                        color: $purple;
                    }
                }

                &.open {
                    > svg {
                        transform: rotate(0deg) translateY(0.25rem);
                    }
                }
            }

            &.collapsedCell {
                padding: 0;

                .MorphioTable .MuiTableHead-root .MuiTableCell-head {
                    background-color: $white;
                }
            }
        }
    }

    .MuiTableRow-root.totalsRow {
        .MuiTableCell-root {
            border-top: 0.1875rem solid $grey-lines;
            border-bottom: none;

            &:first-child {
                border-radius: 0 0 0 0.625rem;
            }

            &:last-child {
                border-radius: 0 0 0.625rem 0;
            }
        }
    }

    .MuiTableRow-root {
        transition: background-color 0.3s ease-in-out;

        &.MuiTableRow-hover {
            &:hover {
                background-color: $grey-bg;

                .MuiTableCell-root {
                    background-color: $grey-bg;
                }

                .MuiTableCell-body {
                    .collapseToggle {
                        svg {
                            fill: $purple;
                        }

                        > span {
                            color: $purple;
                        }

                        &:hover,
                        &.open {
                            svg {
                                fill: $purple;
                            }

                            > span {
                                color: $purple;
                            }
                        }
                    }
                }
            }
        }

        &.collapsedOpen,
        &.collapsedOpen.MuiTableRow-hover:hover {
            background-color: $purple-light;

            .MuiTableCell-root {
                background-color: $purple-light;
            }
        }

        &.hasCollapsedComponent:not(.collapsedOpen) {
            .MuiTableCell-body {
                border-bottom-color: transparent;
            }
        }
    }

    &.allowScroll {
        max-width: none;
    }

    &.verticalAlignTop {
        .MuiTableBody-root .MuiTableCell-body {
            vertical-align: top;
        }
    }
}

.MuiPaper-root.MuiPopover-paper {
    border-radius: 0.5rem;
    border: 1px solid $grey-lines;
    box-shadow: 0 0.25rem 0.5rem 0 rgba(51, 57, 68, 0.05);

    .MuiList-root {
        padding: 0;

        .MuiListItem-root {
            padding: 0.625rem;
            display: flex;
            align-items: center;
            justify-content: center;
            transition: background-color 0.3s ease;
            border-radius: 0.5rem;
            margin: 0 0.5rem;
            font-family: $primary-font;
            font-size: 0.875rem;
            line-height: 1;
            color: $grey-graphite;

            &:first-of-type {
                margin-top: 0.5rem;
            }

            &:last-of-type {
                margin-bottom: 0.5rem;
            }

            &:hover {
                background-color: $purple-light;
                color: $purple;
            }

            &.Mui-selected {
                background-color: transparent;
                font-weight: 700;

                &:hover {
                    background-color: $purple-light;
                }
            }
        }
    }
}

.PaginationTable {
    .MuiTableFooter-root {
        .MuiTablePagination-root.MuiTableCell-root {
            border: none;
            font-family: $primary-font;

            p {
                font-family: $primary-font;
            }

            .MuiTablePagination-toolbar {
                justify-content: center;
                padding: 0.75rem;

                .MuiTablePagination-caption {
                    letter-spacing: 0;
                }

                .MuiTablePagination-spacer {
                    display: none;
                }

                .MuiSelect-select {
                    font-family: $primary-font;

                    &:focus {
                        background-color: transparent;
                    }
                }

                .MuiSelect-selectMenu {
                    min-height: 0;
                }

                .MuiTablePagination-actions {
                    .MuiIconButton-root {
                        transition: background-color 0.3s ease-in-out;
                        border-radius: 0.5rem;
                        padding: 0.5rem;

                        svg {
                            transition: fill 0.3s ease-in-out;
                        }

                        &:hover {
                            background-color: $purple-light;

                            svg {
                                fill: $purple;
                            }
                        }

                        .MuiTouchRipple-root {
                            display: none;
                        }
                    }
                }
            }
        }
    }

    &.allowScroll {
        .MuiTableFooter-root {
            .MuiTablePagination-root.MuiTableCell-root {
                border-top: 1px solid $grey-lines;
            }
        }
    }
}

@-moz-document url-prefix() {
    .MorphioTable .MuiTableBody-root .MuiTableCell-body.expandContentHeight {
        height: auto;
    }
}
